import VTooltip from "v-tooltip";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import VueCarousel from "vue-carousel";
import VueGtag from "vue-gtag";
import VueModal from "vue-js-modal";
import Meta from "vue-meta";
import vSelect from "vue-select";
import VueToast from "vue-toast-notification";
import App from "./App.vue";
import router from "./router";
// import Carousel3d from 'vue-carousel-3d'
// css file - font loader
import "./assets/css/typo.css";
import AOS from "aos";
import "aos/dist/aos.css";

import VueElementLoading from "vue-element-loading";
import "vue-select/dist/vue-select.css";
import "vue-toast-notification/dist/index.css";

Vue.config.productionTip = false;

Vue.component("VueElementLoading", VueElementLoading);
Vue.component("ApexChart", VueApexCharts);

Vue.use(
  VueGtag,
  {
    config: { id: "UA-115905174-1" },
    appName: "Higo Website",
    pageTrackerScreenviewEnabled: true
  },
  router
);

// varousel component
Vue.use(VueCarousel);
Vue.use(VTooltip);
Vue.use(VueToast);
Vue.use(VueModal);
Vue.use(Meta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});
Vue.component("v-select", vSelect);

new Vue({
  router,
  render: (h) => h(App),
  mounted: () => {
    document.dispatchEvent(new Event("x-app-rendered"));
    AOS.init();
  }
}).$mount("#app");
