<template>
  <div id="app" data-server-rendered="true">
    <router-view />
  </div>
</template>

<script>
export default {
  // async beforeCreate() {
  // const allowedIP = ["118.99.110.222", "175.103.39.115", "103.141.230.133"];
  // const response = await fetch("https://api.ipify.org?format=json");
  // const IPClient = await response.json();
  // if (!allowedIP.includes(IPClient.ip)) {
  //  window.location.href = "https://higo.id";
  // }
  // },
};
</script>
<style>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#app {
  font-family: "Akzidenz-light", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
</style>
