import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        title: "Home Page - HIGO",
        metaTags: [
          {
            name: "description",
            content: "The home page"
          }
        ]
      }
    },
    // {
    //   path: "/annualreport2023",
    //   name: "annualreport2023",
    //   component: () => import("./views/Redirect2023.vue")
    // },
    {
      path: "/annualreport2024",
      name: "annualreport2024",
      component: () => import("./views/AnnualReport2024.vue")
    },
    {
      path: "/about-us",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue")
    },
    {
      path: "/higospot",
      name: "higospot",
      component: () => import("@/views/HigospotNew.vue")
    },
    {
      path: "/wifi-advertising",
      name: "Wifi Advertising",
      component: () => import("./views/WifiAdvertising.vue")
    },
    {
      path: "/integrated-digital-agency",
      name: "Integrated Digital Agency",
      component: () => import("./views/IntegratedDigitalAgency.vue")
    },
    {
      path: "/case-study",
      name: "casestudy",
      component: () => import("./views/CaseStudy.vue")
    },
    {
      path: "/case-study/21-factory",
      name: "21 Factory",
      component: () => import("./views/21Factory.vue")
    },
    {
      path: "/case-study/devon",
      name: "Devon",
      component: () => import("./views/Devon.vue")
    },
    {
      path: "/case-study/walking-drums",
      name: "Walking Drums",
      component: () => import("./views/WalkingDrums.vue")
    },
    {
      path: "/case-study/la",
      name: "La",
      component: () => import("./views/La.vue")
    },
    {
      path: "/case-study/abbott",
      name: "Abbott",
      component: () => import("./views/Abbott.vue")
    },
    {
      path: "/case-study/home-credit",
      name: "Home Credit",
      component: () => import("./views/HomeCredit.vue")
    },
    {
      path: "/case-study/kintaro-sushi",
      name: "Kintaro Suhsi",
      component: () => import("./views/Kintaro.vue")
    },
    {
      path: "/contact-us",
      name: "contact",
      component: () => import("./views/Contact.vue")
    },
    {
      path: "/terms-of-use-higospot",
      name: "term",
      component: () => import("./views/TermsCondition.vue")
    },
    {
      path: "/privacy-policy-higospot",
      name: "privacy",
      component: () => import("./views/PrivacyPolicy")
    },
    {
      path: "/careers",
      name: "career",
      component: () => import("./views/Career.vue")
    },
    {
      path: "/careers/:job",
      name: "Career Form",
      component: () => import("./views/CareerForm.vue")
    },
    {
      path: "*",
      redirect: "/"
    }
  ]
});
